<template>
    <AModal
        :visible="visible"
        title="Detail Status"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ }">
                    <ASpace>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import moment from 'moment'
import _ from 'lodash'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            columns: [
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Nama User',
                    dataIndex: 'user_name',
                },
                {
                    title: 'Role User',
                    dataIndex: 'user_role',
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'activity_date',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'description',
                },
                {
                    title: 'Kode Target',
                    dataIndex: 'target_code',
                },
            ],
            endpoint: `/api/snop/target-penjualan-distributor/list-activity/${props.item.id}`,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                page: 1,
                "per-page": 10,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return _.pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleModalCancel,
            handleTableChange,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
